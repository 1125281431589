<template>
  <div class="col-md-12">
        <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="30%" class="text-center">Año/Mes</th>
                    <th width="20%" class="text-center">Aporte</th>
                    <th width="20%" class="text-center">Sepelio</th>
                    <th width="20%" class="text-center">Total</th>
                    <th width="5%" class="text-center">Acc.</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in contributions_detail" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.year +" / "+ NameMonth(item.month) }}</td>
                      <td class="align-middle text-left">
                        <b-input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.contribution"></b-input>
                      </td>
                      <td class="align-middle text-left">
                        <b-input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.burial"></b-input>
                      </td>
                      <td class="align-middle text-right">{{ item.total }}</td>
                      <td class="align-middle text-center">
                        <button type="button" @click="DeleteDetail(it)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>  
                      </td>

                  </tr>
                </tbody>
              </table>
        </div>
  </div>
</template>
<style scoped>
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';
import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "SaleDetail",
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    EditDetail,
    DeleteDetail,
    NameUnitMeasure,
    NameMonth,
    ...mapActions('Contribution',['mLoadEditContributionDetail']),
    ...mapActions('Contribution',['mLoadDeleteContributionDetail']),
    
  },
  computed: {
    ...mapState('Contribution',['contributions_detail','total']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};

function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function NameMonth(code) {
  let name = '';
  switch (code) {
    case '01': name = 'Enero'; break;
    case '02': name = 'Febrero'; break;
    case '03': name = 'Marzo'; break;
    case '04': name = 'Abril'; break;
    case '05': name = 'Mayo'; break;
    case '06': name = 'Junio'; break;
    case '07': name = 'Julio'; break;
    case '08': name = 'Agosto'; break;
    case '09': name = 'Septiembre'; break;
    case '10': name = 'Octubre'; break;
    case '11': name = 'Noviembre'; break;
    case '12': name = 'Diciembre'; break;
  
  }
  return name;
}

function EditDetail(index) {
  let name = '';
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha modificado el aporte ' + name, type: 'warn'})
  this.mLoadEditContributionDetail(index);
}

function DeleteDetail(index) {
  let name = '';
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha eliminado el aporte ' + name, type: 'warn'})
  this.mLoadDeleteContributionDetail(index);
}
</script>
