<template>
  <div>
    <b-modal size="md" class="modal-size" hide-footer v-model="modal_contribution" title="APORTE">
      <b-row>
        <b-col md="12">
          <b-form @submit.prevent="Validate">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Año :">
                    <b-form-select v-model="contribution_detail.year" :options="year"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Mes:">
                    <b-form-select v-model="contribution_detail.month" :options="month"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Aporte:">
                    <b-form-input v-model="contribution_detail.contribution" type="number" step="any" class="text-right"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Sepelio:">
                    <b-form-input v-model="contribution_detail.burial" type="number" step="any" class="text-right"></b-form-input>
                  </b-form-group>
                </b-col>
               
                <b-col md="12">
                  <b-form-group>
                    <b-button type="submit" class="form-control" variant="primary">Agregar</b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>


</style>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../../pages/Loading'
export default {
  name: "ModalClient",
    components:{
      LoadingComponent,
  },
  data() {
    return {
        module:'Contribution',
        role:'',
        isLoading: false,
        modal_contribution: false,
        id_partner:'',
        contribution_detail: {
          id_contribution_detail: '',
          id_contribution: '',
          year: '',
          month: '',
          month_name: '',
          contribution: 0,
          burial: 0,
          total: 0,
          id_liquidation: 0,
          state: 0,
        },
        year:[
          { value: '1990', text:'1990'},
          { value: '1991', text:'1991'},
          { value: '1992', text:'1992'},
          { value: '1993', text:'1993'},
          { value: '1994', text:'1994'},
          { value: '1995', text:'1995'},
          { value: '1996', text:'1996'},
          { value: '1997', text:'1997'},
          { value: '1998', text:'1998'},
          { value: '1999', text:'1999'},
          { value: '2000', text:'2000'},
          { value: '2001', text:'2001'},
          { value: '2002', text:'2002'},
          { value: '2003', text:'2003'},
          { value: '2004', text:'2004'},
          { value: '2005', text:'2005'},
          { value: '2006', text:'2006'},
          { value: '2007', text:'2007'},
          { value: '2008', text:'2008'},
          { value: '2009', text:'2009'},
          { value: '2010', text:'2010'},
          { value: '2011', text:'2011'},
          { value: '2012', text:'2012'},
          { value: '2013', text:'2013'},
          { value: '2014', text:'2014'},
          { value: '2015', text:'2015'},
          { value: '2016', text:'2016'},
          { value: '2017', text:'2017'},
          { value: '2018', text:'2018'},
          { value: '2019', text:'2019'},
          { value: '2020', text:'2020'},
          { value: '2021', text:'2021'},
          { value: '2022', text:'2022'},
          { value: '2023', text:'2023'},
          { value: '2024', text:'2024'},
          { value: '2025', text:'2025'},
          { value: '2026', text:'2026'},
          { value: '2027', text:'2027'},
          { value: '2028', text:'2028'},
          { value: '2029', text:'2029'},
          { value: '2030', text:'2030'},
          
 
        ],
        month:[
          { value: '01', text:'Enero'},
          { value: '02', text:'Febrero'},
          { value: '03', text:'Marzo'},
          { value: '04', text:'Abril'},
          { value: '05', text:'Mayo'},
          { value: '06', text:'Junio'},
          { value: '07', text:'Julio'},
          { value: '08', text:'Agosto'},
          { value: '09', text:'Septiembre'},
          { value: '10', text:'Octubre'},
          { value: '11', text:'Noviembre'},
          { value: '12', text:'Diciembre'},
        ],
    };
  },
  created (){
  
  },
  mounted () {
    EventBus.$on('ModalContributionShow', (id_partner,role) => {
      console.log(id_partner)
      this.modal_contribution = true;
      this.id_partner = id_partner;
      this.role = role;
      this.GetContribution();
      this.LastContribution();
    });
    
  },
  methods: {
    GetContribution,
    LastContribution,
    Validate,

      ...mapActions('Contribution',['mLoadAddContributionDetail']),
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};
function GetContribution() {
  this.contribution_detail.contribution = parseFloat(10).toFixed(2);
  this.contribution_detail.burial = parseFloat(5).toFixed(2);
}

function LastContribution() {
  let me = this;
  let url = me.url_base + "contribution/last-contribution/"+this.id_partner;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.contribution_detail.year = response.data.result.year;
          me.contribution_detail.month = response.data.result.month;   
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
 
  if (this.contribution_detail.year.length != 0 && this.contribution_detail.month.length != 0 && this.contribution_detail.contribution.length != 0 && this.contribution_detail.burial.length != 0) {
    let data = {
    year: this.contribution_detail.year,
    month: this.contribution_detail.month,
    contribution: this.contribution_detail.contribution,
    burial: this.contribution_detail.burial,
    total: (parseFloat(this.contribution_detail.contribution) + parseFloat(this.contribution_detail.burial)).toFixed(2),
  };
    this.mLoadAddContributionDetail(data);
    
  }
    
}

</script>
